/*
  1. Base
*/
body {
    padding: 0;
    margin: 0;
    font-family: Lato, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
}

@media screen and (min-width: 900px) {
    body {
        overflow: hidden;
    }
}

/*
  2. Components
*/
div#blue-playlist-container {
    height: 100%;
}

div#loading {
    color: #ffffff;
    text-align: center;
    padding-top: 80px;
    font-size: 4em;
}

div#amplitude-player {
    background: #ffffff;
    height: 100%;
    display: flex;
    display: none;
}

/* Small only */
@media screen and (max-width: 899px) {
    div#amplitude-player {
        flex-direction: column;
    }
}

/* Large and up */
@media screen and (min-width: 900px) {
    div#amplitude-player {
        /* max-height: 715px; */
        height: 100%;
    }
}

div.mobile-playlist-button {
    display: none;
}

div#amplitude-left {
    padding: 0px;
    background: #f1f1f1;
    border-right: 1px solid #cfd8dc;
    width: 50%;
    display: flex;
    flex-direction: column;

    img.album-art {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: calc(100% - 270px);
        margin: auto;
    }

    div#player-left-bottom {
        flex: 1;
        background-color: #f1f1f1;
        padding: 20px 10px;

        div#volume-container:after {
            content: "";
            display: table;
            clear: both;
        }
    }
}

/* Small only */
@media screen and (max-width: 899px) {
    div.mobile-playlist-button {
        display: block;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        svg.icon {
            position: absolute;
            left: 15px;
            top: 10px;
        }
    }

    div#playlist-close {
        display: none;
        position: fixed;
        width: 100%;
        z-index: 99999;
        background: #f1f1f1;
    }

    div#amplitude-player div#amplitude-left {
        width: 100%;
        height: 100%;
        border-right: 0;

        img[amplitude-song-info="cover_art_url"] {
            width: auto;
            height: auto;
        }
    }
}

div#amplitude-right {
    padding: 0px;
    overflow-y: scroll;
    width: 50%;
    display: flex;
    flex-direction: column;

    div.song {
        cursor: pointer;
        padding: 10px;
        position: relative;

        div.playlist-copy {
            background: #eeeeee;
            color: #444444;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
            width: 100%;
            line-height: 93px;
            display: none;
        }

        div.song-now-playing-icon-container {
            float: left;
            width: 20px;
            height: 20px;
            margin-right: 10px;

            svg.now-playing {
                display: none;
                margin-top: 3px;
            }
        }

        div.play-button-container {
            display: none;
            background: url("img/list-play-light.png") no-repeat;
            width: 22px;
            height: 22px;
            margin-top: 3px;

            &:hover {
                background: url("img/list-play-hover.png") no-repeat;
            }
        }

        &.amplitude-active-song-container {
            div.song-now-playing-icon-container svg.now-playing {
                display: block;
            }

            &:hover div.play-button-container {
                display: none;
            }
        }

        div.song-meta-data {
            float: left;
            width: calc(100% - 190px);

            span.song-title {
                color: #272726;
                font-size: 16px;
                display: block;
                font-weight: 300;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span.song-artist {
                color: #607d8b;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span.song-genres {
                color: #999999;
                font-size: 14px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        button.playlist-share {
            float: left;
            display: block;
            margin: 18px 10px;

            background: transparent;
            border: none;
            padding: 0;
            cursor: pointer;
        }

        svg.playlist-download {
            float: left;
            display: block;
            margin: 18px 10px;
        }

        span.song-duration {
            float: left;
            width: 55px;
            text-align: center;
            line-height: 66px;
            color: #607d8b;
            font-size: 16px;
            font-weight: 500;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
}

/* Small only */
@media screen and (max-width: 899px) {
    div#amplitude-player div#amplitude-right {
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        background: #fff;
        z-index: 9999;

        div.mobile-playlist-button {
            position: fixed;
            width: 100%;
        }

        div#playlist {
            padding-top: 50px;
        }
    }
}

div#progress-container {
    width: 60%;
    float: left;
    position: relative;
    height: 20px;
    cursor: pointer;
    /*
      IE 11
    */

    &:hover {
        input[type="range"].amplitude-song-slider::-webkit-slider-thumb {
            display: block;
        }

        input[type="range"].amplitude-song-slider::-moz-range-thumb {
            visibility: visible;
        }
    }

    progress#song-played-progress {
        width: 100%;
        position: absolute;
        left: 0;
        top: 8px;
        right: 0;
        width: 100%;
        z-index: 60;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 4px;
        border-radius: 5px;
        background: transparent;
        border: none;
        /* Needed for Firefox */
    }

    progress#song-played-progress[value]::-webkit-progress-bar {
        background: none;
        border-radius: 5px;
    }

    progress#song-played-progress[value]::-webkit-progress-value {
        background-color: #00a0ff;
        border-radius: 5px;
    }

    progress#song-played-progress::-moz-progress-bar {
        background: none;
        border-radius: 5px;
        background-color: #00a0ff;
        height: 5px;
        margin-top: -2px;
    }

    progress#song-buffered-progress {
        position: absolute;
        left: 0;
        top: 8px;
        right: 0;
        width: 100%;
        z-index: 10;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 4px;
        border-radius: 5px;
        background: transparent;
        border: none;
        background-color: #d7dee3;
    }

    progress#song-buffered-progress[value]::-webkit-progress-bar {
        background-color: #cfd8dc;
        border-radius: 5px;
    }

    progress#song-buffered-progress[value]::-webkit-progress-value {
        background-color: #78909c;
        border-radius: 5px;
        transition: width 0.1s ease;
    }

    progress#song-buffered-progress::-moz-progress-bar {
        background: none;
        border-radius: 5px;
        background-color: #78909c;
        height: 5px;
        margin-top: -2px;
    }

    progress::-ms-fill {
        border: none;
    }

    input[type="range"] {
        -webkit-appearance: none;
        width: 100%;
        margin: 7.5px 0;
        position: absolute;
        z-index: 9999;
        top: -7px;
        height: 20px;
        cursor: pointer;
        background-color: inherit;

        &:focus {
            outline: none;
        }
    }

    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 0px;
        cursor: pointer;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
        background: #0075a9;
        border-radius: 0px;
        border: 0px solid #010101;
    }

    input[type="range"]::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 1px solid #00a0ff;
        height: 15px;
        width: 15px;
        border-radius: 16px;
        background: #00a0ff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7.5px;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: #00adfb;
    }

    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 0px;
        cursor: pointer;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
        background: #0075a9;
        border-radius: 0px;
        border: 0px solid #010101;
    }

    input[type="range"]::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 1px solid #00a0ff;
        height: 15px;
        width: 15px;
        border-radius: 16px;
        background: #00a0ff;
        cursor: pointer;
    }

    input[type="range"]::-ms-track {
        width: 100%;
        height: 0px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    input[type="range"]::-ms-fill-lower {
        background: #003d57;
        border: 0px solid #010101;
        border-radius: 0px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    }

    input[type="range"]::-ms-fill-upper {
        background: #0075a9;
        border: 0px solid #010101;
        border-radius: 0px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    }

    input[type="range"]::-ms-thumb {
        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
        border: 1px solid #00a0ff;
        height: 15px;
        width: 15px;
        border-radius: 16px;
        background: #00a0ff;
        cursor: pointer;
        height: 0px;
        display: block;
    }

    input[type="range"]:focus::-ms-fill-lower {
        background: #0075a9;
    }

    input[type="range"]:focus::-ms-fill-upper {
        background: #00adfb;
    }
}

@media all and (-ms-high-contrast: none) {
    div#progress-container *::-ms-backdrop,
    div#progress-container progress#song-played-progress {
        color: #00a0ff;
        border: none;
        background-color: #cfd8dc;
    }
}

@supports (-ms-ime-align: auto) {
    div#progress-container progress#song-played-progress {
        color: #00a0ff;
        border: none;
    }
}

@-moz-document url-prefix() {
    div#progress-container progress#song-buffered-progress {
        top: 9px;
        border: none;
    }
}

@media all and (-ms-high-contrast: none) {
    div#progress-container *::-ms-backdrop,
    div#progress-container progress#song-buffered-progress {
        color: #78909c;
        border: none;
    }
}

@supports (-ms-ime-align: auto) {
    div#progress-container progress#song-buffered-progress {
        color: #78909c;
        border: none;
    }
}

@media all and (-ms-high-contrast: none) {
    div#progress-container *::-ms-backdrop,
    div#progress-container input[type="range"].amplitude-song-slider {
        padding: 0px;
    }
    div#progress-container *::-ms-backdrop,
    div#progress-container input[type="range"].amplitude-song-slider::-ms-thumb {
        height: 15px;
        width: 15px;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -8px;
    }
    div#progress-container *::-ms-backdrop,
    div#progress-container input[type="range"].amplitude-song-slider::-ms-track {
        border-width: 15px 0;
        border-color: transparent;
    }
    div#progress-container *::-ms-backdrop,
    div#progress-container input[type="range"].amplitude-song-slider::-ms-fill-lower {
        background: #cfd8dc;
        border-radius: 10px;
    }
    div#progress-container *::-ms-backdrop,
    div#progress-container input[type="range"].amplitude-song-slider::-ms-fill-upper {
        background: #cfd8dc;
        border-radius: 10px;
    }
}

@supports (-ms-ime-align: auto) {
    div#progress-container input[type="range"].amplitude-song-slider::-ms-thumb {
        height: 15px;
        width: 15px;
        margin-top: 3px;
    }
}

div#control-container {
    margin-top: 25px;
    margin-top: 20px;

    div#repeat-container {
        width: 25%;
        float: left;
        padding-top: 20px;

        div#repeat {
            width: 24px;
            height: 19px;
            cursor: pointer;

            &.amplitude-repeat-off {
                background: url("img/repeat-off.svg");
            }

            &.amplitude-repeat-on {
                background: url("img/repeat-on.svg");
            }
        }

        div#shuffle {
            width: 23px;
            height: 19px;
            cursor: pointer;
            float: right;

            &.amplitude-shuffle-off {
                background: url("img/shuffle-off.svg");
            }

            &.amplitude-shuffle-on {
                background: url("img/shuffle-on.svg");
            }
        }
    }

    div#central-control-container {
        width: 50%;
        float: left;

        div#central-controls {
            width: 130px;
            margin: auto;

            div#previous {
                display: inline-block;
                width: 40px;
                height: 40px;
                cursor: pointer;
                background: url("img/prev.svg");
                background-repeat: no-repeat;
                float: left;
                margin-top: 10px;
                margin-right: -5px;
            }

            div#play-pause {
                display: inline-block;
                width: 60px;
                height: 60px;
                cursor: pointer;
                float: left;

                &.amplitude-paused {
                    background: url("img/play.svg");
                }

                &.amplitude-playing {
                    background: url("img/pause.svg");
                }
            }

            div#next {
                display: inline-block;
                width: 40px;
                height: 40px;
                cursor: pointer;
                background: url("img/next.svg");
                background-repeat: no-repeat;
                float: left;
                margin-top: 10px;
                margin-left: -5px;
            }
        }
    }

    div#volume-container {
        width: 25%;
        float: left;
        padding-top: 20px;
    }

    div#shuffle-right {
        width: 23px;
        height: 19px;
        cursor: pointer;
        margin: auto;

        &.amplitude-shuffle-off {
            background: url("img/shuffle-off.svg");
        }

        &.amplitude-shuffle-on {
            background: url("img/shuffle-on.svg");
        }
    }

    div.amplitude-mute {
        cursor: pointer;
        width: 25px;
        height: 19px;
        float: left;

        &.amplitude-not-muted {
            background: url("img/volume.svg");
            background-repeat: no-repeat;
        }

        &.amplitude-muted {
            background: url("img/mute.svg");
            background-repeat: no-repeat;
        }
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@media all and (-ms-high-contrast: none) {
    div#control-container *::-ms-backdrop,
    div#control-container {
        margin-top: 40px;
        float: none;
    }
}

/* Small only */
@media screen and (max-width: 599px) {
    div#amplitude-player {
        div#repeat-container {
            div#repeat {
                margin-left: auto;
                margin-right: auto;
                float: none;
            }

            div#shuffle {
                display: none;
            }
        }

        div#volume-container {
            div.volume-controls {
                display: none;
            }

            div#shuffle-right {
                display: block;
            }
        }
    }
}

/* Medium only */
@media screen and (min-width: 600px) and (max-width: 899px) {
    div#amplitude-player {
        div#repeat-container {
            div#repeat {
                margin-left: auto;
                margin-right: auto;
                float: none;
            }

            div#shuffle {
                display: none;
            }
        }

        div#volume-container {
            div.volume-controls {
                display: none;
            }

            div#shuffle-right {
                display: block;
            }
        }
    }
}

/* Large and up */
@media screen and (min-width: 900px) {
    div#amplitude-player div#repeat-container div#repeat {
        margin-left: 10px;
        margin-right: 20px;
        float: left;
    }
    div#amplitude-player div#volume-container div#shuffle-right {
        display: none;
    }
}

input[type="range"].amplitude-volume-slider {
    -webkit-appearance: none;
    width: calc(100% - 33px);
    float: left;
    margin-top: 8px;
    margin-left: 5px;
}

@-moz-document url-prefix() {
    input[type="range"].amplitude-volume-slider {
        margin-top: 0px;
    }
}

@supports (-ms-ime-align: auto) {
    input[type="range"].amplitude-volume-slider {
        margin-top: 3px;
        height: 12px;
        background-color: rgba(255, 255, 255, 0) !important;
        z-index: 999;
        position: relative;
    }

    div.ms-range-fix {
        height: 1px;
        background-color: #a9a9a9;
        width: 67%;
        float: right;
        margin-top: -6px;
        z-index: 9;
        position: relative;
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    input[type="range"].amplitude-volume-slider {
        margin-top: -24px;
        background-color: rgba(255, 255, 255, 0) !important;
    }
}

input[type="range"] {
    &.amplitude-volume-slider {
        &:focus {
            outline: none;

            &::-webkit-slider-runnable-track {
                background: #cfd8dc;
            }

            &::-ms-fill-lower {
                background: #cfd8dc;
            }

            &::-ms-fill-upper {
                background: #cfd8dc;
            }
        }

        &::-webkit-slider-runnable-track {
            width: 75%;
            height: 1px;
            cursor: pointer;
            animate: 0.2s;
            background: #cfd8dc;
        }

        &::-webkit-slider-thumb {
            height: 10px;
            width: 10px;
            border-radius: 10px;
            background: #00a0ff;
            cursor: pointer;
            margin-top: -4px;
            -webkit-appearance: none;
        }

        &::-moz-range-track {
            width: 100%;
            height: 1px;
            cursor: pointer;
            animate: 0.2s;
            background: #cfd8dc;
        }

        &::-moz-range-thumb {
            height: 10px;
            width: 10px;
            border-radius: 10px;
            background: #00a0ff;
            cursor: pointer;
            margin-top: -4px;
        }

        &::-ms-track {
            width: 100%;
            height: 1px;
            cursor: pointer;
            animate: 0.2s;
            background: transparent;
            /*leave room for the larger thumb to overflow with a transparent border */
            border-color: transparent;
            border-width: 15px 0;
            /*remove default tick marks*/
            color: transparent;
        }

        &::-ms-fill-lower {
            background: #cfd8dc;
            border-radius: 10px;
        }

        &::-ms-fill-upper {
            background: #cfd8dc;
            border-radius: 10px;
        }

        &::-ms-thumb {
            height: 10px;
            width: 10px;
            border-radius: 10px;
            background: #00a0ff;
            cursor: pointer;
            margin-top: 2px;
        }

        &::-ms-tooltip {
            display: none;
        }
    }
}

div#time-container {
    span.current-time {
        color: #607d8b;
        font-size: 14px;
        font-weight: 700;
        float: left;
        width: 20%;
        text-align: center;
    }

    span.duration {
        color: #607d8b;
        font-size: 14px;
        font-weight: 700;
        float: left;
        width: 20%;
        text-align: center;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

div#meta-container {
    text-align: center;
    margin-top: 5px;

    span.song-name {
        display: block;
        color: #272726;
        font-size: 20px;
        font-family: "Open Sans", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div.song-artist-album {
        color: #607d8b;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: "Open Sans", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
            display: block;
        }
    }
}

/*
    3. Layout
  */
body {
    background-image: -webkit-linear-gradient(316deg, #3bd2ae 0%, #36bac2 100%);
    background-image: linear-gradient(-226deg, #3bd2ae 0%, #36bac2 100%);
    height: 100vh;
}
div.amplitude-wave-form {
    margin-top: -14px;

    svg {
        stroke: #00a0ff;
        height: 50px;
        width: 100%;
        stroke-width: 0.5px;

        g {
            stroke: #00a0ff;
            height: 50px;
            width: 100%;

            path {
                stroke: #00a0ff;
                height: 50px;
                width: 100%;
            }
        }
    }
}

div#large-visualization {
    width: 100%;
    background-color: black;
    /* visibility: hidden; */
    display: none;
}
/*
    4. Pages
  */
/*
    5. Themes
  */
/*
    6. Utils
  */
/*
    7. Vendors
  */

/*
    8. Animations
*/

.slide-in-top {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
   * Generated by Animista on 2019-3-25 18:39:54
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
/**
   * ----------------------------------------
   * animation slide-in-top
   * ----------------------------------------
   */
@-webkit-keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}
.slide-out-top {
    -webkit-animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
    animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

/* ----------------------------------------------
   * Generated by Animista on 2019-3-25 18:45:17
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
/**
   * ----------------------------------------
   * animation slide-out-top
   * ----------------------------------------
   */
@-webkit-keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        opacity: 0;
    }
}
@keyframes slide-out-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        opacity: 0;
    }
}
